import { ref } from "vue"
import { db } from "@/firebaseConfig"

export default function useCollection(collection) {
  const error = ref(null)
  const isPending = ref(false)

  const addDoc = async (doc) => {
    error.value = null
    isPending.value = true

    try {
      const res = await db.collection(collection).add(doc)
      isPending.value = false
      return res
    } catch (err) {
      console.error(err.message)
      error.value = 'Could not save the record'
      isPending.value = false
    }
  }

  const setDoc = async (id, doc) => {
    try {
      await db.collection(collection).doc(id).set(doc)
    } catch (err) {
      console.error(err.message)
      error.value = 'Could not save the record'
    }
  }

  return { error, isPending, addDoc, setDoc }
}
